import React from 'react';
import { ContainerBox, FeatureLink, FeatureTitle, FeaturePhoto, FeatureImage } from './ProductFeaturesSectionV2Mob.styles';
import { homePageData } from '@data-ts/pages/home';
import { JoykitIcon } from '@components/JoykitIcon';
import { CloudImage } from '@components/CloudImage';
import { Text } from '@components/typography';

type AssetType = { path: string; className: string; style?: Object };
type Feature = {
  id: string;
  to: string;
  label: string;
  desc: string;
  assets: AssetType[];
};

const sectionData = homePageData.sections.features;

export const ProductFeaturesSectionV2Mob = () => {
  const featureItems: Feature[] = [
    {
      id: 'home-features-to-wedding-website',
      to: '/wedding-website',
      label: sectionData.weddingWebsite.listItemLabel,
      desc: 'Beautiful, free and uniquely yours, with smart features for all your planning needs.',
      assets: [
        { path: 'wedding-website/router-ww-bg.jpg', className: 'featureBackgroundImg', style: {} },
        { path: 'wedding-website/router-ww-ui.png', className: 'featureUiImg', style: {} },
        { path: 'wedding-website/router-ww-couple.png', className: 'featureBaseImg', style: {} },
      ],
    },
    {
      id: 'home-features-to-hotel-room-blocks',
      to: '/hotel-room-blocks',
      label: sectionData.hotelRoomBlocks.listItemLabel,
      desc: 'Unlock discounted group hotel rates for your guests without using your credit card.',
      assets: [{ path: 'travel/room-blocks_static_v2.jpg', className: 'featureBackgroundImg', style: {} }],
    },
    {
      id: 'home-features-to-registry',
      to: '/registry',
      label: sectionData.registry.listItemLabel,
      desc: 'Your All-in-One registry, from gifts, to zero-fee cash funds, to honeymoon experiences.',
      assets: [{ path: 'registry/registry-ww.png', className: 'featureBackgroundImg', style: {} }],
    },
    {
      id: 'home-features-to-guest-list',
      to: '/guest-list',
      label: sectionData.guestList.listItemLabel,
      desc: 'Organize and track every detail of your nuptials from your free planning hub.',
      assets: [
        { path: 'guest-list/router-gl-bg.jpg', className: 'featureBackgroundImg', style: {} },
        { path: 'guest-list/router-gl-ui-bg.png', className: 'featureUiImg', style: {} },
        { path: 'guest-list/router-gl-ui.png', className: 'featureUiImg', style: {} },
        { path: 'guest-list/router-gl-couple.png', className: 'featureBaseImg', style: {} },
      ],
    },
    {
      id: 'home-features-save-the-dates',
      to: '/save-the-date',
      label: sectionData.saveTheDates.listItemLabel,
      desc: 'Hundreds of design options to announce your wedding in style. Send for free online or order matching prints.',
      assets: [
        { path: 'save-the-dates/router-std-bg.jpg', className: 'featureBackgroundImg', style: {} },
        { path: 'save-the-dates/router-std-couple.png', className: 'featureBaseImg', style: { zIndex: 1 } },
        { path: 'save-the-dates/router-std-cards.png', className: 'featureUiImg', style: {} },
      ],
    },
    {
      id: 'home-features-to-invitations',
      to: '/wedding-invitations',
      label: sectionData.invitations.listItemLabel,
      desc: 'Design the perfect invitation for your big day. Send for free online or order matching prints.',
      assets: [
        { path: 'invites/invites-background-v3.jpg', className: 'featureBackgroundImg', style: {} },
        { path: 'invites/invites-cards-v4.png', className: 'featureBaseImg', style: {} },
        { path: 'invites/invites-couple-v3.png', className: 'featureUiImg', style: {} },
      ],
    },
    {
      id: 'home-features-to-smart-rsvp',
      to: '/online-rsvp',
      label: sectionData.smartRSVP.listItemLabel,
      desc: 'Ask any question and track every answer with powerful and free Smart RSVP.',
      assets: [
        { path: 'inv-rsvp/router-inv-rsvp-bg.jpg', className: 'featureBackgroundImg', style: {} },
        {
          path: 'inv-rsvp/router-inv-rsvp-card.jpg',
          className: 'featureUiImg',
          style: { maxHeight: '100%', objectFit: 'contain', zIndex: 1 },
        },
        { path: 'inv-rsvp/router-inv-rsvp-ui-bubbles.png', className: 'featureUiImg', style: {} },
        {
          path: 'inv-rsvp/router-inv-rsvp-couple.png',
          className: 'featureBaseImg',
          style: { height: '100%', zIndex: 1 },
        },
      ],
    },
    {
      id: 'home-features-to-guest-app',
      to: '/app',
      label: sectionData.guestApp.listItemLabel,
      desc: 'Every detail at your fingertips with a free mobile app to match your website.',
      assets: [
        { path: 'mobile/router-mobile-bg.jpg', className: 'featureBackgroundImg', style: {} },
        {
          path: 'mobile/router-mobile-couple.png',
          className: 'featureBaseImg',
          style: { height: '100%', objectFit: 'contain', marginTop: '43%' },
        },
        { path: 'mobile/router-mobile-phone_v2.svg', className: 'featureUiImg', style: {} },
      ],
    },
  ];
  return (
    <ContainerBox as="section" id="RoutersMobile">
      {featureItems.map(feature => {
        const { id, to, label, desc, assets } = feature;
        return (
          <FeatureLink key={label} href={to} id={id}>
            <FeaturePhoto>
              {assets.map((asset: AssetType) => {
                const { path, className, style } = asset;
                return className === 'featureBackgroundImg' ? (
                  <FeatureImage
                    key={path}
                    className={className}
                    src={`home/router-sections/router-${path}`}
                    style={style}
                    optimization="default"
                  />
                ) : (
                  <CloudImage
                    alt={path}
                    key={path}
                    className={className}
                    src={`home/router-sections/router-${path}`}
                    width="100%"
                    optimization="default"
                    style={style}
                  />
                );
              })}
            </FeaturePhoto>
            <FeatureTitle variant="hed40" color="yellow5" tagName="span" textAlign="center">
              {label}
              <JoykitIcon name="rightArrow" color="yellow5" />
            </FeatureTitle>
            <Text variant="featureCopy17" color="yellow6" tagName="span" textAlign="center">
              {desc}
            </Text>
          </FeatureLink>
        );
      })}
    </ContainerBox>
  );
};
