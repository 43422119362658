import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Layout, Seo } from '@layout';
import { homePageData } from '@data-ts/pages/home';
import { HomeHeroSection } from '@sections/home/HomeHeroSection/HomeHeroSection';
import { HomeNewsSection } from '@sections/home/HomeNewsSection';
import { gsap } from 'gsap';
import { useTheme } from '@styledComponents';
import { useIsMounted } from '@hooks/useIsMounted';
import { topHeaderLinkHeroTransitionClassName } from 'src/layout/components/Header/Header.styles';
import { WaterfallCouple } from '@sections/home/WaterfallCouple/WaterfallCouple';
import { graphql, useStaticQuery } from 'gatsby';
import { BlogCarousel } from '@components/BlogCarousel';
import { BabyRegistry } from '@sections/shared/BabyRegistry';
import { Box } from '@components/Box';
import { RoomBlockSection } from '@sections/home';
import { ProductFeaturesSectionV2, ProductFeaturesSectionV2Mob } from '@sections/home/ProductFeaturesSectionV2';
import { ContactCollectorSection } from '@sections/home/ContactCollectorSection';

const IndexPage: React.SFC = () => {
  const data = useStaticQuery(graphql`
    {
      featureBlogs {
        id
        blogs {
          link
          title
          excerpt
          mediaLink
        }
      }
    }
  `);
  const headerRef = useRef<HTMLElement>(null);
  const [headerTimeline, setHeaderTimeline] = useState<Maybe<gsap.core.Timeline>>();
  const theme = useTheme();
  const isMounted = useIsMounted();

  useLayoutEffect(() => {
    let newHeaderTimeline: ReturnType<typeof gsap.timeline>;
    if (isMounted && headerRef.current) {
      newHeaderTimeline = gsap.timeline({ paused: true });
      const textBlockColorTo = theme.colors.linkDefault;
      newHeaderTimeline.to(
        headerRef.current.querySelectorAll(`a.${topHeaderLinkHeroTransitionClassName}`),
        {
          color: textBlockColorTo,
        },
        0
      );
      setHeaderTimeline(newHeaderTimeline);
    }
  }, [isMounted, theme]);

  return (
    <Layout
      headerRef={headerRef}
      headerScrollThresholdVh={35}
      isTransparentHeader={true}
      transparentHeaderButtonVariant="defaultPrimeWeddingWebsites"
      page="home"
    >
      <Seo title={homePageData.seo.title} description={homePageData.seo.content} canonicalURL={'https://withjoy.com/'} />
      <HomeHeroSection />
      <Box>
        <ProductFeaturesSectionV2 />
        <ProductFeaturesSectionV2Mob />
      </Box>
      <HomeNewsSection />
      <ContactCollectorSection />
      <Box as="section" id="Home_Baby_Registry" paddingX={[0, 6]} paddingY={6}>
        <BabyRegistry page="home" />
      </Box>
      <RoomBlockSection />
      <BlogCarousel blogs={data.featureBlogs.blogs} sectionId="Home_Featured_Blog" />
      <WaterfallCouple />
    </Layout>
  );
};

export default IndexPage;
